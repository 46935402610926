<template>
  <router-view />
</template>

<style lang="scss">
@import "bootstrap/scss/bootstrap";
@import "./assets/template/css/materialdesignicons.min.css";
@import "./assets/template/css/pe-icon-7-stroke.css";
@import "./assets/template/scss/style";
</style>
