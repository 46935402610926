import { RouteRecordRaw } from "vue-router";
import Home from "@/views/Home.vue";
import About from "@/components/About.vue";
import Services from "@/components/Services.vue";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "",
        name: "About",
        component: About,
      },
      {
        path: "oferta",
        name: "Services",
        component: Services,
      },
    ],
  },
];
